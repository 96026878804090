
.eCommerce-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 0 20px;
}
.tab-top {
    position: relative;
    .back-btn {
        position: absolute;
        right: 0;
        top: 0;
    }
}
